import { EnvironmentName } from './environment-types';

export const environment = {
  name: 'production' as EnvironmentName,
  production: true,
  isSaaSProject: false,
  rateLimiterHeader: '',
  maxUserInactivityMinutes: 15,
  paymentGraphQLUrl: 'https://payment.services.platform.finverity.com/graphql',
  documentGraphQLUrl: 'https://document.services.platform.finverity.com/graphql',
  notificationGraphQLUrl: 'https://notification.services.platform.finverity.com/graphql',
  notificationWSGraphQLUrl: 'wss://notification.services.platform.finverity.com/graphql',
  kycGraphQLUrl: 'https://kyc.services.platform.finverity.com/graphql',
  actionLogGraphQlUrl: 'https://action-log.services.platform.finverity.com/graphql',
  socketUrl: 'https://api.services.platform.finverity.com/',
  serverUrl: 'https://api.services.platform.finverity.com/',
  SSOUrl: 'https://sso.services.platform.finverity.com/',
  platform: {
    title: 'Finverity Admin Portal',
    company: 'Finverity LTD',
    logoUrl: 'assets/logo/logo_finverity.svg',
    saasSuffixLogoPath: 'assets/powered_by_logo.svg',
    faviconUrl: 'assets/favicon/favicon_finverity.png',
  },
  currenciesUrl: 'https://payment.services.platform.finverity.com/utils/currency-config',
  userDocumentsUrl: 'https://sso.services.platform.finverity.com/utils/document-signs',
  businessProcessSettings: {
    isPayerPrepaymentActive: true,
  },
  userSnap: {
    enabled: true,
    GlobalAPIKey: '8d1ac1d2-2145-492b-9ad8-62f358f85d2d',
    ProjectAPIKey: 'cb2ddc44-eae9-44f2-82f3-13f48c673d7d',
  },
  sentry: {
    enabled: false,
    dsn: 'https://aea0ee4f0b1b49f6b18d5d15aeda5420@o4504480764723200.ingest.sentry.io/4504519285538816',
  },
};

export const AUTH_API_KEY = '@api-SSO';
export const THIRD_PARTY_API_KEY = '@api-third-party';
